import React from "react"
import { useTheme } from "emotion-theming"
import Layout from "../../../components/Layout"
import "../../../styles/global.css"
import { Section } from "../../../components/PageComponents/Section"
import SEO from "../../../components/seo"
import styled from "@emotion/styled"
import { Link } from "gatsby"

const CarrerPage = () => {
  const theme = useTheme()

  return (
    <Layout>
      <SEO title="evoli - Jobb och karriär" />
      <Section bgColor={theme.colors.primaryBackground}>
        <Contentcontainer>
          <h3>Jobb och karriär</h3>
          <p>
            Nedan ser du vilka tjänster vi söker just nu, klicka på länkarna för
            respektive jobb för att läsa mer. Vi tar också alltid emot spontana
            ansökningar som mailas till{" "}
            <a href="mailto:info@evoli.se">info@evoli.se</a>.
          </p>
          <Link to="/om-oss/jobb-och-karriar/haskell-developer">
            <strong>Haskellutvecklare</strong>
          </Link>
          <br /> <br />
          <Link to="/om-oss/jobb-och-karriar/react-developer">
            <strong>Reactutvecklare</strong>
          </Link>
        </Contentcontainer>
      </Section>
    </Layout>
  )
}

export default CarrerPage

const Contentcontainer = styled.section`
  color: #292944;
  font-size: 14px;
  max-width: 600px;
  min-height: 300px;

  h3 {
    font-weight: 500;
  }
  h4 {
    color: #4b4b65;
    margin-bottom: 10px;
  }
  a {
    color: #5558ea;
    :hover {
      text-decoration: underline;
    }
  }
`
